import * as SentrySvelte from '@sentry/svelte'
import type { HandleClientError } from '@sveltejs/kit'

// Initialize the Sentry SDK here
SentrySvelte.init({
	dsn: 'https://52c758fe0ffb413e9aeb3689e22b309d@o4504940611567616.ingest.sentry.io/4504940618645504',
    environment: import.meta.env.SENTRY_ENVIRONMENT,
	integrations: [new SentrySvelte.BrowserTracing()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0
})

export const handleError: HandleClientError = async ({ error, event }) => {
	const errorId = crypto.randomUUID()
	SentrySvelte.captureException(error, {
		contexts: { sveltekit: { event, errorId } }
	})
	console.error(`${error}`)
	return {
		message: "An unexpected error occurred. We're working on it.",
		errorId
	}
}
