import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68')
];

export const server_loads = [0,3,4,6,5,7];

export const dictionary = {
		"/": [~8],
		"/about": [9],
		"/admin": [10,[2]],
		"/admin/backup": [~11,[2]],
		"/admin/content": [~12,[2]],
		"/admin/covers": [~13,[2]],
		"/admin/db": [14,[2]],
		"/admin/engagement": [~15,[2]],
		"/admin/lists": [~16,[2]],
		"/admin/themes": [~17,[2]],
		"/admin/trending": [~18,[2]],
		"/admin/updates": [~19,[2]],
		"/admin/users": [20,[2,3]],
		"/admin/users/stats": [21,[2,3]],
		"/api/auth/confirm": [22],
		"/book": [~23],
		"/book/[bookID]": [~24],
		"/book/[bookID]/reviews": [~25],
		"/eula": [26],
		"/footer/contact": [27],
		"/footer/legal/terms": [28],
		"/foryou": [~29],
		"/iOS": [30],
		"/import": [~31],
		"/journals": [32,[4]],
		"/journals/edit": [~37,[4]],
		"/journals/summary": [38,[4,6]],
		"/journals/summary/filter": [~39,[4,6]],
		"/journals/[journalID]": [33,[4,5]],
		"/journals/[journalID]/export": [~35,[4,5]],
		"/journals/[journalID]/public": [~36,[4,5]],
		"/journals/[journalID]/[entryID]": [~34,[4,5]],
		"/login": [~40],
		"/logout": [~41],
		"/newsearch": [~42],
		"/privacy": [43],
		"/profileold": [~45],
		"/profile": [~44],
		"/publicjournal/searchItems": [~48],
		"/publicjournal/search": [~47],
		"/publicjournal/[journalID]": [~46],
		"/readinglog": [~49],
		"/saved": [~50],
		"/saved/allbooks": [~51],
		"/search": [~52],
		"/search/tropes": [~53],
		"/signupnew": [~55],
		"/signup": [~54],
		"/social": [56,[7]],
		"/social/filter": [~57,[7]],
		"/stats": [~58],
		"/tropes": [~59],
		"/updates": [~60],
		"/upgrade": [~61],
		"/upgrade/cancel": [62],
		"/upgrade/success": [~63],
		"/usersearch": [~67],
		"/users/all": [~66],
		"/users/[username]": [~64],
		"/users/[username]/follows": [~65],
		"/wish/[viewName]": [~68]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';